import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import 'intersection-observer';
import {
  history,
  AuthContextProvider,
  authRoutes,
  requireAuth,
} from 'packages/core';
import { theme, Loader } from 'packages/eid-ui';
import { Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from 'context/AppContext';
import App from 'containers/App';
import 'packages/eid-ui/globalStyles.css';
import config from 'config';

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Router history={history}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider
        config={{
          settings: {
            client_id: config.CLIENT_ID,
            authority: config.AUTHORITY_URL,
            scope: config.SCOPE,
            response_type: config.RESPONSE_TYPE ?? 'code',
          },
          initialValue: {},
          extractAuthData: async (_, decodedToken) => {},
        }}
      >
        <Switch>
          {authRoutes.map((ar) => (
            <Route
              key={ar.path}
              exact
              path={ar.path}
              render={(props) => (
                <ar.component {...props} render={() => <Loader />} />
              )}
            />
          ))}
          <Route
            path=""
            component={requireAuth(
              (props) => (
                <AppContextProvider>
                  <App {...props} />
                </AppContextProvider>
              ),
              <Loader />,
            )}
          />
        </Switch>
      </AuthContextProvider>
    </MuiThemeProvider>
  </Router>,
  MOUNT_NODE,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
