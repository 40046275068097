import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { AzureAnalyticsIcon, Icon, EidLogoIcon } from 'packages/eid-icons';
import { Header, Dropdown } from 'packages/eid-ui';
import envConfig from 'config';
import { default as useUserManager } from 'packages/core/auth/useUserManager';
import config from 'config';

const AzAnalyticsHeader = ({ history }) => {
  const userManager = useUserManager();
  const [userId, setUserId] = useState();
  const [name, setName] = useState('');
  const imageUrl = `${envConfig.API_URL}/UI/Common/Person/Image?personGUID=${userId}&size=Thumb`;

  useEffect(() => {
    userManager.getUser().then((user) => {
      setUserId(user?.profile?.oid);
      setName(user?.profile?.name);
    });
  }, [userManager]);

  return (
    <Header>
      <Header.IconLink logo href={`${config.APP_SUBPATH}/`} rightDivider>
        <EidLogoIcon />
      </Header.IconLink>
      <Header.IconLink logo href={`${config.APP_SUBPATH}/`}>
        <AzureAnalyticsIcon />
      </Header.IconLink>
      <Header.Title>Azure License Analytics</Header.Title>
      <Header.RightSection>
        <Header.AccountDropdown
          style={{ width: '120px', marginRight: '20px' }}
          imageUrl={imageUrl}
          friendlyName={name}
        >
          <Dropdown.Item
            onClick={() => {
              history.push('/signout');
            }}
          >
            <Icon name="LogOut" color="#919193" />
            Sign Out
          </Dropdown.Item>
        </Header.AccountDropdown>
      </Header.RightSection>
    </Header>
  );
};
export default withRouter(AzAnalyticsHeader);
