import { useCallback } from 'react';
import { useAuthState } from 'packages/core/auth';
import envConfig from 'config';

const useAxiosOptions = () => {
  const [{ accessToken }] = useAuthState();

  return useCallback(
    () => ({
      baseURL: `${envConfig.API_URL}/api/webui/v1`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
        client_id: envConfig.EID_CLIENT_ID,
        scope: envConfig.EID_SCOPE,
        'X-EmpowerID-API-Key': envConfig.EID_API_KEY
      },
    }),
    [envConfig.EID_CLIENT_ID, accessToken],
  );
};

export default useAxiosOptions;
