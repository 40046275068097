import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import An from '../Helpers/AbbreviateNumber.js';
import Trunc from '../Helpers/TruncateString.js';
import useWindowSize from '../../hooks/useWindowSize';
import useColorPalette from 'hooks/useColorPalette.js';

const Bar = (props) => {
  const windowSize = useWindowSize();
  let isMobile = windowSize.width < 756;
  let labelAngle = props.config.labelAngle;
  let legendOffset = props.config.legendOffset;
  let state = props.config.state;
  let friendlyName = props.config.friendlyName;
  const getcolorPalette = useColorPalette();
  const colors = getcolorPalette('purple');
  let fill = [];
  let stateModel = [];

  const isEmptyObject = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  if (state.length > 0) {
    if (state.length > 0 && !(state.length == 1 && isEmptyObject(state[0]))) {
      stateModel = state.map((x) => {
        let res = {};
        Object.keys(x).forEach((y) => {
          if (!x[y].hasOwnProperty('Value')) {
            res[y] = x[y];
          } else {
            if (Object.keys(x[y]).length > 0) {
              res[y] = x[y]['Value'];

              Object.keys(x[y]).forEach((z) => {
                if (z != 'Value') res[y + ' ' + z] = x[y][z];
              });
            }
          }
        });
        return res;
      });
    } else stateModel = [];
  }

  let getStateModel = () => {
    if (isEmptyObject(stateModel)) {
      stateModel = [
        {
          Name: 'No data',
          Value: 0,
        },
      ];
    } else if (stateModel.length == 0) {
      stateModel = [
        {
          Name: "Couldn't Retrieve data",
          Value: 0,
        },
      ];
    }
    return stateModel;
  };

  if (stateModel.length != 0) {
    /* Set fill for fields - currently setting at Random */
    const fillTypes = ['dots', 'lines', ''];
    fill = stateModel.map((x) => {
      return {
        match: {
          id: x.Name,
        },
        id: fillTypes[Math.floor(Math.random() * fillTypes.length)],
      };
    });
  }
  let indexBy = stateModel[0] ? Object.keys(stateModel[0])[0] : '';
  const CustomTickX = (tick) => {
    return (
      <g transform={`translate(${tick.x},${tick.y})`} style={{ opacity: 1 }}>
        <line
          x1="0"
          x2="0"
          y1="0"
          y2="5"
          style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
        ></line>
        <text
          dominant-baseline="central"
          text-anchor="end"
          transform="translate(0,10) rotate(-45)"
          style={{
            fontFamily: 'sans-serif',
            fontSize: '11px',
            fill: 'rgb(51, 51, 51)',
          }}
        >
          {An(tick.value)}
        </text>
      </g>
    );
  };

  const CustomTickY = (tick) => {
    let truncValue =
      tick.value != undefined && isMobile ? Trunc(tick.value, 6) : tick.value;
    return (
      <g transform={`translate(${tick.x},${tick.y})`} style={{ opacity: 1 }}>
        <title>{tick.value}</title>
        <line
          x1="0"
          x2="0"
          y1="0"
          y2="5"
          style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
        ></line>
        <text
          dominant-baseline="central"
          text-anchor="end"
          transform="translate(-10,0) rotate(0)"
          style={{
            fontFamily: 'sans-serif',
            fontSize: '11px',
            fill: 'rgb(51, 51, 51)',
          }}
        >
          {tick.value ? truncValue : ''}
        </text>
      </g>
    );
  };
  let chartMargin =
    props.config.chartMargin != undefined
      ? props.config.chartMargin
      : { top: 20, right: 180, bottom: 100, left: 250 };

  return (
    <ResponsiveBar
      layout="horizontal"
      data={getStateModel()}
      keys={stateModel[0] ? Object.keys(stateModel[0]) : []}
      indexBy={indexBy}
      margin={chartMargin}
      padding={0.3}
      label={(d) => {
        switch (d.data[d.id + ' ValueType']) {
          case 'Integer':
            return An(d.value);
          case 'Currency':
            return '$' + An(d.value);
          case 'Percentage':
            return d.value + '%';
        }
        return An(d.value);
      }}
      colors={colors.palette}
      defs={[]}
      tooltip={({ id, value, color, data }) => (
        <strong style={{ color }}>
          <table>
            <tbody>
              <tr>
                <td valign="top">{id}</td>
                <td valign="top">{value}</td>
              </tr>
              <tr>
                <td valign="top">Description</td>
                <td valign="top">
                  {data[id + ' tooltip']
                    ? data[id + ' tooltip']['Description']
                    : ''}
                </td>
              </tr>
              <tr>
                <td valign="top">Price Per Unit</td>
                <td valign="top">
                  {data[id + ' tooltip']
                    ? data[id + ' tooltip']['Price Per Unit']
                    : ''}
                </td>
              </tr>
              <tr>
                <td valign="top">Total Units</td>
                <td valign="top">
                  {data[id + ' tooltip']
                    ? data[id + ' tooltip']['Total Units']
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </strong>
      )}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: labelAngle ? labelAngle : 0,
        legend: indexBy.toString(),
        legendPosition: 'middle',
        legendOffset: legendOffset ? legendOffset : -150,
        renderTick: CustomTickY,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: friendlyName,
        legendPosition: 'middle',
        legendOffset: 50,
        renderTick: CustomTickX,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: isMobile ? 'bottom-left' : 'bottom',
          direction: isMobile ? 'column' : 'row',
          justify: false,
          translateX: isMobile ? -80 : -30,
          translateY: isMobile ? 100 : 80,
          itemWidth: 120,
          itemHeight: 0,
          itemsSpacing: isMobile ? 12 : 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: isMobile ? 10 : 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      labelTextColor={'#ffffff'}
    />
  );
};

export default Bar;
