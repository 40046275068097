import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SingleStat from '../SingleStat'
import useWindowSize from 'hooks/useWindowSize.js'
import useAxiosOptions from 'hooks/useAxiosOptions'

const CardStat = (props) => {
    const getAxiosOptions = useAxiosOptions();
    const windowSize = useWindowSize();
    let isMobile = windowSize.width < 600;
    let styles = {
        marginBottom: isMobile ? '0px' : '10px'
    };
    let statRequestParams = {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 0, "start": 0, "pageLength": 500, "totalCount": -1 }
    }

    let config = props.config
    statRequestParams.Parameters["empowerIDStatID"] = config.statID

    const [state, setState] = useState([])
    const [letterIcon, setLetterIcon] = useState("#")
    useEffect(() => {
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${statRequestParams["TypeName"]}/${statRequestParams["MethodName"]}`,
                data: statRequestParams,
            })
            let response = result.data.Data
            if (Array.isArray(response)) setState(response[0]);
            else if (response) {
                setState(response)
                if (config.valueType == "Integer" || config.valueType == undefined) setLetterIcon("#")
                if (config.valueType == "Currency") setLetterIcon("$")
                if (config.valueType == "Percentage") setLetterIcon("%")
            }
        }
        fetchData()
    }, [])

    let resp = {
        state,
        letterIcon,
        valueType: config.valueType
    }

    return (
        <div style={styles} className="card-container panel panel-default">
            <div style={{ height: '100%', width: '100%' }}>
                <SingleStat config={resp} />
            </div>
        </div>
    )
}

export default CardStat
