import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import An from '../Helpers/AbbreviateNumber.js';
import useWindowSize from 'hooks/useWindowSize.js';
import useColorPalette from 'hooks/useColorPalette.js';

function Pie(props) {
    const windowSize = useWindowSize();
    let isMobile = windowSize.width < 400;
    let state = props.config.state
    let friendlyName = props.config.friendlyName
    const getcolorPalette = useColorPalette();
    const colors = getcolorPalette("purple");
    let fill = []
    let stateModel = []

    const isEmptyObject = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    if (state.length > 0) {
        /* Set fill for fields - currently setting at Random */
        const fillTypes = ['dots', 'lines', 'squares', '']
        fill = state.map(x => {
            return {
                match: {
                    id: x.Name
                },
                id: fillTypes[Math.floor(Math.random() * fillTypes.length + 1)]
            }
        })


        /* map field values to objects for the chart */
        if (state.length > 0 && !(state.length == 1 && isEmptyObject(state[0]))) {
            stateModel = state.map(x => {
                return {
                    id: x.Name,
                    label: x.Name,
                    value: x.Value,
                    color: colors[Math.floor(Math.random() * colors.length + 1)],
                    valueType: x.ValueType
                }
            })
        }
        else stateModel = {}

    }
    const valueTypeItems = {
        Integer: '',
        Currency: "$",
        Percentage: "%"
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let getStateModel = () => {
        if (isEmptyObject(stateModel)) {
            stateModel = [{
                id: "No data",
                label: "No data",
                value: 100,
                color: "hsl(0, 67%, 50%)"
            }]
        }
        else if (stateModel.length == 0) {
            stateModel = [{
                id: "Couldn't Retrieve data",
                label: "Couldn't Retrieve data",
                value: 100,
                color: "hsl(0, 67%, 50%)"
            }]
        }
        return stateModel
    }

    let setMargin = props.chartMargin ? props.chartMargin : { top: 50, right: 0, bottom: 60, left: 0 }
    let setLegend = {
        anchor: 'bottom-left',
        direction: 'column',
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000'
                }
            }
        ]
    }
    return (
        <ResponsivePie
            data={getStateModel()}
            margin={setMargin}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={colors.palette}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={isMobile ? 360 : 10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            sliceLabel={function (e) {
                switch (e.valueType) {
                    case "Integer":
                        return An(e.value)
                    case "Currency":
                        return "$" + An(e.value)
                    case "Percentage":
                        return e.value + "%"
                }
                return e.value
            }}
            tooltip={({ id, value, color, valueType }) => (
                <strong style={{ color }}>
                    {id}: {valueType == "Currency" ? valueTypeItems[valueType] + numberWithCommas(value) : numberWithCommas(value) + valueTypeItems[valueType]}
                </strong>
            )}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={5}
            slicesLabelsTextColor="#ffffff"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            defs={[]}
            legends={isMobile ? [setLegend] : []}
            fill={[...fill]}
        />
    )
}

export default Pie