import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFoundPage } from 'packages/eid-ui';
import AZ from '../AZ';
import config from 'config';

function App() {
  return (
    <>
      <Switch>
        <Route path={`${config.APP_SUBPATH}/`} component={AZ} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default App;
