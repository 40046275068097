import React from 'react';
import CardBar from '../../components/Charts/Cards/CardBar.js';
import CardHorizontalPieGroup from '../../components/Charts/Cards/CardHorizontalPieGroup.js';
import PieGroupItem from 'components/Charts/PieGroupItem.js';
import useWindowSize from '../../hooks/useWindowSize.js';

const azureLicenseCountPerSubscriptionAndUserStatus = {
    "TypeName": "EmpowerIDStatOrganizationView",
    "MethodName": "GetByEmpowerIDStatID",
    "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
    "Parameters": { "empowerIDStatID": 525, "start": 0, "pageLength": 500, "totalCount": -1 }
}
const azureLicenseCountPerSubscriptionAssignedVsUnassignedLicenses = {
    "TypeName": "EmpowerIDStatOrganizationView",
    "MethodName": "GetByEmpowerIDStatID",
    "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
    "Parameters": { "empowerIDStatID": 583, "start": 0, "pageLength": 500, "totalCount": -1 }
}
const azureGlobalUsageActiveVsInactiveLast30Days = {
    Exchange: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 561, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    SharePoint: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 562, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    SkypeForBusiness: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 563, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Yammer: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 564, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Teams: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 565, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    OneDrive: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 567, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const headerStyle = {
    margin: '50px 0 50px 0'
}


const UserStatus = (props) => {
    const fullWidthStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
        regStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            width: '49%',
            float: 'left'
        }
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regStyle}>
                <CardBar key="17" chartHeight={props.chartHeight} legendOffset={props.legendOffset} labelAngle={props.labelAngle} chartMargin={props.chartMargin} title="Azure License Count Per Subscription And User Status" config={{ statParams: azureLicenseCountPerSubscriptionAndUserStatus, statType: "Bar" }} />
            </div>
        </>
    )
}

const AssignmentStatus = (props) => {
    const fullWidthStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
        regStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            width: '50%',
            float: 'right'
        }
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regStyle}>
                <CardBar key="18" chartHeight={props.chartHeight} legendOffset={props.legendOffset} labelAngle={props.labelAngle} chartMargin={props.chartMargin} title="Azure License Count Per Subscription Assigned Vs Unassigned Licenses" config={{ statParams: azureLicenseCountPerSubscriptionAssignedVsUnassignedLicenses, statType: "Bar" }} />
            </div>
        </>
    )
}
const RegView = (props) => {
    return (
        <>
            <UserStatus isFullWidth={props.width < 1620} chartHeight={'700px'} legendOffset={-150} labelAngle={45} chartMargin={{ top: 20, right: 80, bottom: 100, left: 250 }} />
            <AssignmentStatus isFullWidth={props.width < 1620} chartHeight={'700px'} legendOffset={-230} labelAngle={45} chartMargin={{ top: 20, right: 80, bottom: 100, left: 250 }} />
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%', margin: '0.5% 0%' }}>
                <CardHorizontalPieGroup title="Azure Global Usage Active vs Inactive the last 30 days" key="29" groupTitles={["Exchange", "SharePoint", "Skype For Business", "Yammer", "Teams", "OneDrive"]} child1={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Exchange} />} child2={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.SharePoint} />} child3={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.SkypeForBusiness} />} child4={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Yammer} />} child5={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Teams} />} child6={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.OneDrive} />} />
            </div>
        </>
    )
}
const MobView = (props) => {
    return (
        <>
            <UserStatus isFullWidth={props.width < 1620} chartHeight={'750px'} legendOffset={-200} labelAngle={45} chartMargin={{ top: 20, right: 80, bottom: 120, left: 100 }} />
            <AssignmentStatus isFullWidth={props.width < 1620} chartHeight={'750px'} legendOffset={-150} labelAngle={45} chartMargin={{ top: 20, right: 80, bottom: 120, left: 100 }} />
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%', margin: '0.5% 0%' }}>
                <CardHorizontalPieGroup title="Azure Global Usage Active vs Inactive the last 30 days" key="29" groupTitles={["Exchange", "SharePoint", "Skype For Business", "Yammer", "Teams", "OneDrive"]} child1={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Exchange} />} child2={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.SharePoint} />} child3={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.SkypeForBusiness} />} child4={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Yammer} />} child5={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.Teams} />} child6={<PieGroupItem config={azureGlobalUsageActiveVsInactiveLast30Days.OneDrive} />} />
            </div>
        </>
    )
}
const LicenseUsage = () => {
    let windowSize = useWindowSize();
    let isMobile = windowSize.width < 600;
    let contentWrapperWidth = '96.5%'
    if (windowSize.width < 600) {
        contentWrapperWidth = '81%'
    }
    else if (windowSize.width < 960) {
        contentWrapperWidth = '95%'
    }
    else if (windowSize.width < 1200) {
        contentWrapperWidth = '96%'
    }
    const contentWrapper = {
        width: contentWrapperWidth,
        maxWidth: "115vw",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
    return (
        <>
            <div style={headerStyle}><h1>License Usage</h1></div>
            <div style={contentWrapper} className="content-wrapper">
                {isMobile ? <MobView width={windowSize.width} /> : <RegView width={windowSize.width} />}
            </div>

        </>
    )
}

export default LicenseUsage