import React from 'react';
import { Layout } from 'packages/eid-ui';
import AzAnalyticsHeader from './AzAnalyticsHeader';
import AzAnalyticsGlobalMenu from './AzAnalyticsGlobalMenu';

const AzAnalyticsLayout = ({ children }) => {
  return (
    <Layout
      header={<AzAnalyticsHeader />}
      globalMenu={
        <div style={{ marginTop: '24px' }}>
          <AzAnalyticsGlobalMenu />
        </div>
      }
      children={
        <div
          style={{
            marginTop: '24px',
          }}
        >
          {children}
        </div>
      }
    />
  );
};

export default AzAnalyticsLayout;
