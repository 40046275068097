import React, { useState, useEffect } from 'react'

function useColorPalette() {
    const isClient = typeof window === 'object';

    const colors = {
        divergent: ["#00876c", "#3d9c73", "#63b179", "#88c580", "#aed987", "#d6ec91", "#ffff9d", "#fee17e", "#fcc267", "#f7a258", "#ef8250", "#e4604e", "#d43d51"],
        purple: ["#22459c", "#7143a1", "#a83c9a", "#d43889", "#f44370", "#ff5e54", "#ff8134", "#ffa600"],
        blues: ["#004c6d", "#115c7f", "#1f6d91", "#2b7fa3", "#3890b5", "#44a3c8", "#50b5da", "#5cc9ed", "#69dcff"],
        lightGreens: ["#029e00", "#37ad36", "#54bb57", "#6fc975", "#89d792", "#a3e5ae", "#bff2c9", "#dbffe4"],
        patrick: ["#00b83e", "#265fb4", "#1fca79", "#2882c7", "#4ddaa9", "#4fa4d3", "#7fe8d0", "#80c4de", "#b5f4ed", "#b5e2eb", "#ecffff"],
        cristiana: ["#0010a3", "#0047c4", "#006fd8", "#0094e4", "#35b7eb", "#88d8f3", "#cdf7ff", "#acdbe7", "#8dc0cf", "#6ea5b9", "#4f8aa3", "#30718d", "#005878"]
    }
    function getPalette(name) {
        return {
            palette: colors[name]
        };
    }

    return getPalette;
}

export default useColorPalette