import React from 'react';
import CardPieToggle from '../../components/Charts/Cards/CardPieToggle.js';
import CardBarToggle from '../../components/Charts/Cards/CardBarToggle.js';
import CardLine from '../../components/Charts/Cards/CardLine.js';
import useWindowSize from '../../hooks/useWindowSize.js';

const azureLicenseCostPerLicensePoolAndSubscription = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 546, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 547, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 548, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const azureLicenseTotalCostPerSubscription = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 552, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 553, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 554, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const azureLicenseCostPerLicensePool = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 534, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 535, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 537, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const azureLicensePerLicensePoolAndUserStatus = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 533, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 532, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 530, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const Timeline12MonthsLicensedAzureAccountsPerAccountStatus = {
    "TypeName": "EmpowerIDStatOrganizationView",
    "MethodName": "GetByEmpowerIDStatID",
    "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
    "Parameters": { "empowerIDStatID": 586, "start": 0, "pageLength": 500, "totalCount": -1 }
}
const headerStyle = {
    margin: '50px 0 50px 0'
}


const fullWidthStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
},
    lineRegWidthStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    lineFullWidthStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    regLeftStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '49%',
        float: 'left'
    },
    regRightStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '50%',
        float: 'right'
    }
const PoolAndSubBar = (props) => {
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regLeftStyle}>
                <CardBarToggle chartMargin={props.poolAndSubMargin} title="Azure License Cost Per License Pool And Subscription" key="9" child1={azureLicenseCostPerLicensePoolAndSubscription.Yearly} child2={azureLicenseCostPerLicensePoolAndSubscription.Monthly} child3={azureLicenseCostPerLicensePoolAndSubscription.Count} />
            </div>
        </>
    )
}

const SubPie = (props) => {
    const windowSize = useWindowSize()
    let isMobile = windowSize.width < 756
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regLeftStyle}>
                <CardPieToggle chartHeight={isMobile ? '500px' : '400px'} chartMargin={props.subMargin} title="Azure License Total Cost Per Subscription" key="19" child1={azureLicenseTotalCostPerSubscription.Yearly} child2={azureLicenseTotalCostPerSubscription.Monthly} child3={azureLicenseTotalCostPerSubscription.Count} />
            </div>
        </>
    )
}

const PoolAndUserBar = (props) => {
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regRightStyle}>
                <CardBarToggle chartMargin={props.poolAndUserMargin} title="Azure License Cost Per License Pool And User Status" key="9" child1={azureLicensePerLicensePoolAndUserStatus.Yearly} child2={azureLicensePerLicensePoolAndUserStatus.Monthly} child3={azureLicensePerLicensePoolAndUserStatus.Count} />
            </div>
        </>
    )
}

const PoolPie = (props) => {
    return (
        <>
            <div style={(props.isFullWidth) ? fullWidthStyle : regRightStyle}>
                <CardPieToggle chartHeight={'400px'} chartMargin={props.poolMargin} title="Azure License Total Cost Per License Pool" key="19" child1={azureLicenseCostPerLicensePool.Yearly} child2={azureLicenseCostPerLicensePool.Monthly} child3={azureLicenseCostPerLicensePool.Count} />
            </div>
        </>
    )
}

const Line = (props) => {
    return (
        <>
            <div style={(props.isFullWidth) ? lineFullWidthStyle : lineRegWidthStyle}>
                <CardLine chartHeight={'400px'} chartMargin={props.lineMargin} title="Timeline 12 Months Licensed Azure Accounts Per Account Status" key="91" child={Timeline12MonthsLicensedAzureAccountsPerAccountStatus} />
            </div>
        </>
    )
}

const Insights = () => {
    const windowSize = useWindowSize()
    let poolAndSubMargin = { top: 20, right: 80, bottom: 100, left: 100 }
    let PoolAndUserMargin = { top: 20, right: 80, bottom: 100, left: 100 }
    let PoolMargin = { top: 50, right: 0, bottom: 60, left: 0 }
    let SubMargin = { top: 50, right: 0, bottom: 60, left: 0 }
    let LineMargin = { top: 20, right: 180, bottom: 100, left: 250 }
    if (windowSize.width < 1200) {
        poolAndSubMargin = { top: 20, right: 80, bottom: 100, left: 170 }
        PoolAndUserMargin = { top: 20, right: 80, bottom: 100, left: 170 }
        PoolMargin = { top: 0, right: 0, bottom: 60, left: 0 }
        SubMargin = { top: 0, right: 0, bottom: 60, left: 0 }
    }
    if (windowSize.width < 768) {
        poolAndSubMargin = { top: 20, right: 20, bottom: 110, left: 80 }
        PoolAndUserMargin = { top: 20, right: 20, bottom: 110, left: 80 }
        PoolMargin = { top: 0, right: 0, bottom: 60, left: 0 }
        SubMargin = { top: 0, right: 0, bottom: 60, left: 0 }
    }
    let contentWrapperWidth = '96.5%'
    if (windowSize.width < 600) {
        contentWrapperWidth = '81%'
    }
    else if (windowSize.width < 960) {
        contentWrapperWidth = '95%'
    }
    else if (windowSize.width < 1200) {
        contentWrapperWidth = '96%'
    }
    const contentWrapper = {
        width: contentWrapperWidth,
        maxWidth: "115vw",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
    return (
        <>
            <div style={headerStyle}><h1>Insights</h1></div>
            <div style={contentWrapper} className="content-wrapper">
                <PoolAndSubBar isFullWidth={windowSize.width < 1200} poolAndSubMargin={poolAndSubMargin} />
                <PoolAndUserBar isFullWidth={windowSize.width < 1200} poolAndUserMargin={PoolAndUserMargin} />
                <SubPie isFullWidth={windowSize.width < 1200} subMargin={SubMargin} />
                <PoolPie isFullWidth={windowSize.width < 1200} poolMargin={PoolMargin} />
                <Line isFullWidth={windowSize.width < 1200} lineMargin={LineMargin} />
            </div>

        </>
    )
}

export default Insights
