import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import useWindowSize from 'hooks/useWindowSize.js';
import useColorPalette from 'hooks/useColorPalette.js';
function Line(props) {
    const windowSize = useWindowSize();
    let isMobile = windowSize.width < 400;
    const getcolorPalette = useColorPalette();
    const colors = getcolorPalette("purple");
    let state = props.config.state
    let friendlyName = props.config.friendlyName
    let fill = []
    let stateModel = []
    const isEmptyObject = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    if (state.length > 0) {
        /* map field values to objects for the chart */
        if (state.length > 0 && !(state.length == 1 && isEmptyObject(state[0]))) {
            stateModel = state.map(x => {
                return {
                    id: x.id,
                    data: x.data,
                    color: colors[Math.floor(Math.random() * colors.length + 1)]
                }
            })
        }
        else stateModel = {}
    }
    const valueTypeItems = {
        Integer: '',
        Currency: "$",
        Percentage: "%"
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let getStateModel = () => {
        if (isEmptyObject(stateModel)) {
            stateModel = [{
                id: "No data",
                data: [],
                color: "hsl(0, 67%, 50%)"
            }]
        }
        else if (stateModel.length == 0) {
            stateModel = [{
                id: "Couldn't Retrieve data",
                data: [],
                color: "hsl(0, 67%, 50%)"
            }]
        }
        return stateModel
    }
    let setMargin = props.chartMargin ? props.chartMargin : { top: 50, right: 110, bottom: 50, left: 60 }
    return (
        <ResponsiveLine
            data={getStateModel()}
            margin={{ top: 20, right: 60, bottom: 110, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            enableSlices="x"
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -25,
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={colors.palette}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: isMobile ? 'bottom-left' : 'bottom',
                    direction: isMobile ? 'column' : 'row',
                    justify: false,
                    translateX: isMobile ? -60 : 0,
                    translateY: isMobile ? 100 : 60,
                    itemWidth: 120,
                    itemHeight: 0,
                    itemsSpacing: isMobile ? 12 : 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: isMobile ? 10 : 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        {slice.points.map(point => (
                            <div
                                key={point.id}
                                style={{
                                    color: point.serieColor,
                                    padding: '3px 0',
                                }}
                            >
                                <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                            </div>
                        ))}
                    </div>
                )
            }}
        />
    )
}

export default Line