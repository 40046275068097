import React from 'react';
import GaugeChart from 'react-gauge-chart'


function Gauge(props) {
    let state = props.config.state,
        friendlyName = props.config.friendlyName,
        arcLengths = (state.arcLengths != undefined) ? state.arcLengths.substring(1, state.arcLengths.length - 1).split(',') : [0.3, 0.3, 0.4],
        percentageValue = state.percentValue
    return (
        <GaugeChart id={`gauge-chart-` + props.id}
            textColor={'#000000'}
            nrOfLevels={420}
            arcsLength={arcLengths}
            colors={['#d3dbf1', '#87a3ea', '#22459c']}
            needleColor={'#87a3ea'}
            needleBaseColor={'#87a3ea'}
            percent={percentageValue}
            arcPadding={0.02}
        />
    )
}

export default Gauge