import React from 'react'
import { stat } from 'fs'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import An from '../Helpers/AbbreviateNumber.js'
import useWindowSize from 'hooks/useWindowSize.js'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const GroupStat = (props) => {
    const windowSize = useWindowSize();
    let isMobile = windowSize.width < 600;
    const colors = ["#262fa1", "#9230c5", "#0078fa", "#ff6100", "#ba002c", "#ff007b", "#00a4d8", "#dc3131", "#00b83e", "#ffb800", "#265fb4", "#d50393", "#01c29f", "#ff6f57"]
    let icons = props.config.icons
    let item = {
        flexGrow: 1
    }
    let setColor = "#0078fa"

    let state = props.config.state,
        valueType = props.config.valueType

    const valueTypeItems = {
        Integer: '#',
        Currency: "$",
        Percentage: "%"
    }

    const formatTotalStat = (item) => {
        if (Object.keys(item).length > 2) {
            let res = An(item.Value)
            switch (item.ValueType) {
                case "Integer": {
                    res = res
                    return res
                }
                case "Currency": {
                    res = valueTypeItems.Currency + res
                    return res
                }
                case "Percentage": {
                    res = res + valueTypeItems[valueType]
                    return res
                }
                case undefined: {
                    return res
                }
            }
        }
        else return 0
    }
    const tableCellStyle = { display: "flex", flexDirection: "row", justifyContent: 'space-between', flexWrap: "nowrap", borderBottom: "none", backgroundColor: "white", minWidth: isMobile ? "25%" : "unset" }
    const tableItemNameStyle = { fontWeight: "900", padding: "0px", borderBottom: "none", backgroundColor: "white", width: "62%", textAlign: "left" }
    const valueBubbleStyle = { padding: "2px", textAlign: "center", marginLeft: "25px" }
    const lineStyle = { width: "1px", height: "55px", border: "solid 1px #f0f0f2" }

    const ValueBubble = (props) => {
        return (
            <div style={valueBubbleStyle}>
                <p style={{ fontSize: props.fontSize + "px", fontWeight: props.fontWeight, margin: "0", height: "100%", width: "100%" }}>{props.child}</p>
            </div>
        )
    }
    state.map(item => {
        item.icon = icons["" + item.Name.replace(/\s+/g, '')]
    })

    const BroadView = () => {
        let isLessThan1500 = windowSize.width < 1500
        const listItems = state.map((item, index) => {
            return (
                <>
                    <TableCell
                        style={{ ...tableCellStyle, width: isMobile ? "280px" : 100 / state.length + "%", marginRight: index == state.length - 1 ? "0px" : "8px" }}
                        align="right">
                        <div>
                            {<div style={{ display: "flex", direction: "row", maxHeight: "50px", marginLeft: "15px" }}>
                                <img style={{ width: isLessThan1500 ? "25px" : "30px" }} src={item.icon} alt="Logo" />
                                <ValueBubble child={formatTotalStat(item)} fontSize={isLessThan1500 ? 30 : 32} fontWeight={800} />
                            </div>}
                        </div>
                        <div style={tableItemNameStyle}>
                            {<span style={{ display: "flex", direction: "row", maxHeight: "50px" }}>
                                <p style={{ marginLeft: "3px", marginRight: "15px", color: "#5d6870", fontSize: isLessThan1500 ? "14px" : "16px" }}>{item.Name}</p>
                            </span>}
                        </div>
                    </TableCell>
                    {(index != state.length - 1) ? <p style={lineStyle} /> : <></>}
                </>
            )
        });
        return (
            <div>
                <TableContainer style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }} component={Paper} >
                    <Table aria-label="simple table" style={{ width: "100%" }}>
                        <TableBody>
                            <TableRow style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "100%" }} key={1}>
                                {listItems}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const NarrowView = () => {
        const listItems = state.map((item, index) => {
            return (
                <>
                    <TableRow>
                        <TableCell
                            style={{ ...tableCellStyle, width: "5%" }}
                            align="right">
                            {<div style={{ display: "flex", direction: "row", maxHeight: "50px", marginLeft: "15px" }}>
                                <img style={{ width: "30px" }} src={item.icon} alt="Logo" />
                                <ValueBubble child={formatTotalStat(item)} fontSize={32} fontWeight={800} />
                            </div>}
                        </TableCell>
                        <TableCell style={tableItemNameStyle}>
                            {<span style={{ display: "flex", direction: "row", maxHeight: "50px" }}>
                                <p style={{ marginLeft: "3px", color: "#5d6870", fontSize: "16px" }}>{item.Name}</p>
                            </span>}
                        </TableCell>
                    </TableRow>
                </>
            )
        });
        return (
            <div>
                <TableContainer style={{ borderLeft: "groove " + setColor, borderRadius: "10px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} component={Paper} >
                    <Table aria-label="simple table">
                        <TableBody>
                            {listItems}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const MobView = () => {
        const listItems = state.map((item, index) => {
            return (
                <>
                    <TableCell
                        style={{ ...tableCellStyle }}
                        align="right">
                        <div>
                            {<div style={{ display: "flex", direction: "row", maxHeight: "50px", marginLeft: "15px" }}>
                                <img style={{ width: "30px" }} src={item.icon} alt="Logo" />
                                <ValueBubble child={formatTotalStat(item)} fontSize={32} fontWeight={800} />
                            </div>}
                        </div>
                        <div style={tableItemNameStyle}>
                            {<span style={{ display: "flex", direction: "row", maxHeight: "50px" }}>
                                <p style={{ marginLeft: "3px", marginRight: "15px", color: "#5d6870", fontSize: "16px" }}>{item.Name}</p>
                            </span>}
                        </div>
                    </TableCell>
                </>
            )
        });
        return (
            <div>
                <TableContainer style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }} component={Paper} >
                    <Table aria-label="simple table" style={{ border: "none", boxShadow: "none" }}>
                        <TableBody>
                            <TableRow style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', flexWrap: "nowrap" }} key={1}>
                                {listItems}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const RenderView = () => {
        if (windowSize.width < 600) {
            return (<MobView />)
        }
        else if (windowSize.width < 1300 && windowSize.width > 399) {
            return (<NarrowView />)
        }
        else return (<BroadView />)
    }

    return (
        <RenderView />
    )
}

export default GroupStat