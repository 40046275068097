import React from 'react';
import CardPieToggle from '../../components/Charts/Cards/CardPieToggle.js';
import CardBlockGauge from '../../components/Charts/Cards/CardBlockGauge.js'
import useWindowSize from 'hooks/useWindowSize.js';
const azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 555, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 556, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 557, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus = {
    Yearly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 558, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Monthly: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 559, "start": 0, "pageLength": 500, "totalCount": -1 }
    },
    Count: {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 560, "start": 0, "pageLength": 500, "totalCount": -1 }
    }
}
const headerStyle = {
    margin: '50px 0 50px 0'
}

const RegView = () => {
    let windowSize = useWindowSize();
    let isFullWidth = windowSize.width < 1200;
    return (
        <>
            <div style={{ 'display': 'flex', 'width': '100%', 'justifyContent': 'space-around', 'alignItems': 'stretch', margin: '0.5% 0%', marginBottom: '70px' }}>
                <CardBlockGauge key="32" id="4" config={{ gauge: { statID: 581 }, block: { statID: 582 } }} />
            </div>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': isFullWidth ? '100%' : '49%', 'float': 'left' }}>
                <CardPieToggle title="Azure License Pool Possible Savings On Invalid Accounts Per License Pool" key="8" child1={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Yearly} child2={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Monthly} child3={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Count} />
            </div>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': isFullWidth ? '100%' : '50%', 'float': 'right' }}>
                <CardPieToggle title="Azure License Pool Possible Savings On Invalid Accounts Per Account Status" key="88" child1={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Yearly} child2={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Monthly} child3={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Count} />
            </div>
        </>
    )
}
const MobView = () => {
    return (
        <>
            <div style={{ 'display': 'flex', 'width': '100%', 'justifyContent': 'space-around', 'alignItems': 'stretch', margin: '0.5% 0%', marginBottom: '70px' }}>
                <CardBlockGauge key="32" id="2" config={{ gauge: { statID: 581 }, block: { statID: 582 } }} />
            </div>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%' }}>
                <CardPieToggle title="Azure License Pool Possible Savings On Invalid Accounts Per License Pool" key="8" child1={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Yearly} child2={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Monthly} child3={azureLicensePoolPossibleSavingsOnInvalidAccountsPerLicensePool.Count} />
            </div>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%' }}>
                <CardPieToggle title="Azure License Pool Possible Savings On Invalid Accounts Per Account Status" key="88" child1={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Yearly} child2={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Monthly} child3={azureLicensePoolPossibleSavingsOnInvalidAccountsPerAccountStatus.Count} />
            </div>
        </>
    )
}
const PotentialSavings = () => {
    let windowSize = useWindowSize();
    let isMobile = windowSize.width < 600;
    let contentWrapperWidth = '96.5%'
    if (windowSize.width < 600) {
        contentWrapperWidth = '81%'
    }
    else if (windowSize.width < 960) {
        contentWrapperWidth = '95%'
    }
    else if (windowSize.width < 1200) {
        contentWrapperWidth = '96%'
    }
    const contentWrapper = {
        width: contentWrapperWidth,
        maxWidth: "115vw",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
    return (
        <>
            <div style={headerStyle}><h1>Potential Savings</h1></div>
            <div style={contentWrapper} className="content-wrapper">
                {isMobile ? <MobView width={windowSize.width} /> : <RegView width={windowSize.width} />}
            </div>
        </>
    )
}

export default PotentialSavings