import React from 'react';
import CardStat from '../../components/Charts/Cards/CardStat.js';
import CardGroupedStat from '../../components/Charts/Cards/CardGroupedStat.js';
import useWindowSize from 'hooks/useWindowSize.js';

import UserLicenses from 'content/images/GroupStat/1/UserLicenses.svg'
import Subscriptions from 'content/images/GroupStat/1/Subscriptions.svg'
import LicensePools from 'content/images/GroupStat/1/LicensePools.svg'
import AzureTenants from 'content/images/GroupStat/1/AzureTenants.svg'

import Active from 'content/images/GroupStat/2/Active.svg'
import DisabledAccount from 'content/images/GroupStat/2/DisabledAccount.svg'
import Neverloggedin from 'content/images/GroupStat/2/Neverloggedin.svg'
import Notloggedin90days from 'content/images/GroupStat/2/Notloggedin90days.svg'
import TerminatedPerson from 'content/images/GroupStat/2/TerminatedPerson.svg'

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Checkbox,
    Typography
} from '@material-ui/core';

const headerStyle = {
    margin: '50px 0 50px 0'
}

const groupStat1Icons = { UserLicenses, Subscriptions, LicensePools, AzureTenants }
const groupStat2Icons = { Active, DisabledAccount, Neverloggedin, Notloggedin90days, TerminatedPerson }

const CardGroupedStatOneColumn = (props) => {
    return (
        <>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'justifyContent': 'space-between', width: props.width }} className="card-grouped-stat-container-wide">
                <div style={{ 'width': '100%' }}>
                    <CardGroupedStat key="31" icons={groupStat1Icons} config={{ statID: 579 }} />
                </div>
                <div style={{ 'width': '100%' }}>
                    <CardGroupedStat key="31" icons={groupStat2Icons} config={{ statID: 580 }} />
                </div>
            </div>
        </>
    )
}
const CardGroupedStatOneColumnNarrow = () => {
    return (
        <>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'justifyContent': 'space-between', width: "100%" }} className="card-grouped-stat-container-narrow">
                <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '49%', 'justifyContent': 'space-around', 'alignItems': 'stretch', float: 'left' }}>
                    <CardGroupedStat key="31" icons={groupStat1Icons} itemsTotal={4} config={{ statID: 579 }} />
                </div>
                <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '50%', 'justifyContent': 'space-around', 'alignItems': 'stretch', float: 'right' }}>
                    <CardGroupedStat key="31" icons={groupStat2Icons} itemsTotal={5} config={{ statID: 580 }} />
                </div>
            </div>
        </>
    )
}

const classes = {
    root: {
        width: '98%',
        borderLeft: 'groove rgb(186, 0, 44)',
        marginBottom: '10px'
    }
}

const RegView = (windowSize) => {
    return (
        <>
            <div className="card-single-stat-container-wide" style={{ width: "100%" }}>
                <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '49.5%', 'float': 'left' }}>
                    <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'justifyContent': 'space-between', width: '100%' }}>
                        <span style={{ width: windowSize.width < 1200 ? '100%' : '49%' }}>
                            <CardStat key="3" config={{ statID: 517, valueType: "Currency" }} />
                            <CardStat key="5" config={{ statID: 508, valueType: "Currency" }} />
                            <CardStat key="2" config={{ statID: 502 }} />
                        </span>
                        <div style={{ width: windowSize.width < 1200 ? '100%' : '50%' }}>
                            <CardStat key="1" config={{ statID: 518, valueType: "Currency" }} />
                            <CardStat key="14" config={{ statID: 509, valueType: "Currency" }} />
                            <CardStat key="4" config={{ statID: 507 }} />
                        </div>
                    </div>
                </div>
                <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '49.5%', 'float': 'right' }}>
                    <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'justifyContent': 'space-between', width: '100%' }}>
                        <div style={{ width: windowSize.width < 1200 ? '100%' : '49%' }}>
                            <CardStat key="6" config={{ statID: 519, valueType: "Currency" }} />
                            <CardStat key="15" config={{ statID: 510, valueType: "Currency" }} />
                            <CardStat key="11" config={{ statID: 504 }} />
                        </div>
                        <div style={{ width: windowSize.width < 1200 ? '100%' : '50%' }}>
                            <CardStat key="12" config={{ statID: 505 }} />
                            <CardStat key="13" config={{ statID: 506 }} />
                            <CardStat key="16" config={{ statID: 549 }} />
                        </div>
                    </div>
                </div>
            </div>

            {(windowSize.width < 1300) ? <CardGroupedStatOneColumnNarrow /> : <CardGroupedStatOneColumn />}
        </>
    )
}

const MobView = (windowSize) => {
    return (
        <>
            <div style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%' }}>
                <div style={{ 'display': 'flex', 'justifyContent': 'space-between', overflowY: "scroll", marginBottom: "10px" }}>
                    <CardStat key="3" config={{ statID: 517, valueType: "Currency" }} />
                    <CardStat key="1" config={{ statID: 518, valueType: "Currency" }} />
                    <CardStat key="6" config={{ statID: 519, valueType: "Currency" }} />
                    <CardStat key="2" config={{ statID: 502 }} />
                    <CardStat key="12" config={{ statID: 505 }} />
                    <CardStat key="11" config={{ statID: 504 }} />
                </div>
                <div style={{ 'display': 'flex', 'justifyContent': 'space-between', overflowY: "scroll", marginBottom: "10px" }}>
                    <CardStat key="5" config={{ statID: 508, valueType: "Currency" }} />
                    <CardStat key="14" config={{ statID: 509, valueType: "Currency" }} />
                    <CardStat key="15" config={{ statID: 510, valueType: "Currency" }} />
                    <CardStat key="4" config={{ statID: 507 }} />
                    <CardStat key="13" config={{ statID: 506 }} />
                    <CardStat key="16" config={{ statID: 549 }} />
                </div>
                <CardGroupedStatOneColumn width="96%"/>
            </div>
        </>
    )
}
const TopOfMind = () => {
    let windowSize = useWindowSize();
    let isMobile = windowSize.width < 600;
    let contentWrapperWidth = '96.5%'
    if (windowSize.width < 600) {
        contentWrapperWidth = '81%'
    }
    else if (windowSize.width < 960) {
        contentWrapperWidth = '95%'
    }
    else if (windowSize.width < 1200) {
        contentWrapperWidth = '96%'
    }
    const contentWrapper = {
        width: contentWrapperWidth,
        maxWidth: "115vw",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
    return (
        <>
            <div style={headerStyle}><h1>Top of Mind</h1></div>
            <div style={contentWrapper} className="content-wrapper">
                {isMobile ? <MobView width={windowSize.width} /> : <RegView width={windowSize.width} />}
            </div>

        </>
    )
}

export default TopOfMind
