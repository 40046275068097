import React, { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie'
import axios from 'axios'
import useColorPalette from 'hooks/useColorPalette.js';
import useAxiosOptions from 'hooks/useAxiosOptions'

function PieGroupItem(props) {
    const getAxiosOptions = useAxiosOptions();
    const config = props.config
    const [state, setState] = useState({})
    const getcolorPalette = useColorPalette();
    const colors = getcolorPalette("purple");
    const [valueType, setValueType] = useState("Integer")
    useEffect(() => {
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${config["TypeName"]}/${config["MethodName"]}`,
                data: config,
            })
            let response = result.data.Data
            let totalStat = JSON.parse(response.TotalStat)
            setValueType(totalStat.ValueType)
            setState(totalStat.Stat)
        }
        fetchData()
    }, [])
    let fill = []
    let stateModel = []

    const isEmptyObject = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    if (!isEmptyObject(state)) {
        /* map field values to objects for the chart */

        stateModel = Object.keys(state).map(x => {
            return {
                id: x,
                label: x,
                value: state[x]["Value"],
                color: colors[Math.floor(Math.random() * colors.length + 1)],
                tooltip: state[x]["tooltip"]
            }
        })
    }
    const valueTypeItems = {
        Integer: '',
        Currency: "$",
        Percentage: "%"
    }

    let getStateModel = () => {
        if (isEmptyObject(stateModel)) {
            stateModel = [{
                id: "No data",
                label: "No data",
                value: 100,
                color: "hsl(0, 67%, 50%)"
            }]
        }
        else if (stateModel.length == 0) {
            stateModel = [{
                id: "Couldn't Retrieve data",
                label: "Couldn't Retrieve data",
                value: 100,
                color: "hsl(0, 67%, 50%)"
            }]
        }
        return stateModel
    }

    return (
        <ResponsivePie
            data={getStateModel()}
            margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={colors.palette}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={8}
            radialLabelsLinkHorizontalLength={5}
            radialLabelsLinkStrokeWidth={1}
            sliceLabel={function (e) {
                switch (valueType) {
                    case "Integer":
                        return e.value
                    case "Currency":
                        return "$" + e.value
                    case "Percentage":
                        return e.value + "%"
                }
                return e.value
            }}
            tooltip={({ id, value, color, tooltip }) => (
                <strong>
                    <table>
                        <tbody>
                            <tr><td valign="top">{id}</td> <td valign="top">{valueType == "Currency" ? valueTypeItems[valueType] + value : value + valueTypeItems[valueType]}</td></tr>

                            <tr><td valign="top">Description</td> <td valign="top">{tooltip != undefined ? tooltip.Description : ""}</td></tr>
                        </tbody>
                    </table>
                </strong>
            )}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={5}
            slicesLabelsTextColor="#ffffff"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[...fill]}
        />
    )
}

export default PieGroupItem
