import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Line from '../Line.js';
import useWindowSize from 'hooks/useWindowSize.js';
import useAxiosOptions from 'hooks/useAxiosOptions';

const CardLine = (props) => {
    const getAxiosOptions = useAxiosOptions();
    const windowSize = useWindowSize();
    let isMobile = windowSize.size < 756;
    let wrapperStyles = {
        width: '100%',
        margin: '0.5% 0% 0.5% 0%'
    };
    let cardStyles = {
        height: chartHeight ? chartHeight : '37em',
        width: '100%',
        borderRadius: "0px 0px 5px 5px",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff",
        padding: "10px"
    };
    let cardHeaderStyle = {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#fbfbfd",
        padding: "25px"
    }
    let headerFontStyle = {
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#000000"
    }
    let chartMargin = (props.chartMargin) ? props.chartMargin : { top: 50, right: 110, bottom: 50, left: 60 };
    let chartHeight = (props.chartHeight) ? props.chartHeight : '400px';

    const config = props.child
    const [state, setState] = useState([])
    const [friendlyName, setFriendlyName] = useState("Default Stat")
    const [description, setDescription] = useState("Default Stat")
    useEffect(() => {
        const splitBySubstring = (string, substring) => {
            try {
                if (string) {
                    let arr = string.split(substring)
                    return arr.map(x => {
                        if (x.substring([x.length - 2], x.length) != "]}") {
                            x = x + "]}"
                        }
                        return JSON.parse(x)
                    })
                }
                else return []
            }
            catch (err) {
                console.log(err)
            }
        }
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${config["TypeName"]}/${config["MethodName"]}`,
                data: config,
            })
            let response = result.data.Data
            setFriendlyName(response.FriendlyName)
            setDescription(response.Description)
            let splitResponse = splitBySubstring(response.TotalStat, "]},")
            if (Array.isArray(splitResponse)) setState([...splitResponse]);
        }
        fetchData()
    }, [])

    let resp = {
        state: state,
        friendlyName: friendlyName,
        description: description,
        chartMargin
    }

    return (
        <div style={wrapperStyles}>
            <div style={cardHeaderStyle}>
                <i style={headerFontStyle}>{props.title}</i>
                <i style={{ float: "right" }} title={resp["description"]}><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" opacity="0.8" viewBox="0 0 32 32" width="25px" height="25px"><path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z" /></svg></i>
            </div>
            <div style={cardStyles}>
                <div style={{ height: '100%', width: '100%' }}>
                    <Line config={resp} />
                </div>
            </div>
        </div>
    )
}


export default CardLine
