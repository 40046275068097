function AbbreviateNumber(num) {
    let value = parseInt(num)
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = Math.floor(("" + value).length / 4);
    let shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(3));
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
}

export default AbbreviateNumber