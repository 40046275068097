import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios'
import Bar from '../Bar.js'
import useWindowSize from 'hooks/useWindowSize.js'
import useAxiosOptions from 'hooks/useAxiosOptions'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const CardPieToggle = (props) => {
    const getAxiosOptions = useAxiosOptions();
    const windowSize = useWindowSize();
    let isMobile = windowSize.size < 756;
    let wrapperStyles = {
        width: '100%',
        margin: '0.5% 0% 0.5% 0%'
    };
    let cardStyles = {
        width: '100%',
        borderRadius: "0px 0px 5px 5px",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff"
    };
    let cardHeaderStyle = {
        display: "flex",
        flexDirection: "row",
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#fbfbfd",
        padding: "25px",
        position: "relative",
        minHeight: windowSize.width > 1200 ? "100px" : "0px"
    }
    let headerFontStyle = {
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#000000"
    }
    let chartHeight = (props.chartHeight) ? props.chartHeight : '400px';
    let tabStyles = {
        height: chartHeight,
        width: '100%',
        margin: '10px'
    };
    let chartMargin = props.chartMargin;

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const config1 = props.child1
    const [state1, setState1] = useState([])
    const [friendlyName1, setFriendlyName1] = useState("Default Stat")
    const [description1, setDescription1] = useState("Default Stat")
    useEffect(() => {
        const splitBySubstring = (string, substring) => {
            try {
                if (string) {
                    let arr = string.split(substring)
                    return arr.map(x => {
                        if (x.substring(x.length - 3, x.length) != "}}}") {
                            x = x + "}}}"
                        }
                        return JSON.parse(x)
                    })
                }
                else return []
            }
            catch (err) {
                console.log(err)
            }
        }
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${config1["TypeName"]}/${config1["MethodName"]}`,
                data: config1,
            })
            let response = result.data.Data
            setFriendlyName1(response.FriendlyName)
            setDescription1(response.Description)
            let splitResponse = splitBySubstring(response.TotalStat, "}}},")
            if (Array.isArray(splitResponse)) setState1([...splitResponse]);
        }
        fetchData()
    }, [])

    const config2 = props.child2
    const [state2, setState2] = useState([])
    const [friendlyName2, setFriendlyName2] = useState("Default Stat")
    const [description2, setDescription2] = useState("Default Stat")
    useEffect(() => {
        const splitBySubstring = (string, substring) => {
            try {
                if (string) {
                    let arr = string.split(substring)
                    return arr.map(x => {
                        if (x.substring(x.length - 3, x.length) != "}}}") {
                            x = x + "}}}"
                        }
                        return JSON.parse(x)
                    })
                }
                else return []
            }
            catch (err) {
                console.log(err)
            }
        }
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${config2["TypeName"]}/${config2["MethodName"]}`,
                data: config2
            })
            let response = result.data.Data
            setFriendlyName2(response.FriendlyName)
            setDescription2(response.Description)
            let splitResponse = splitBySubstring(response.TotalStat, "}}},")
            if (Array.isArray(splitResponse)) setState2([...splitResponse]);
        }
        fetchData()
    }, [])

    const config3 = props.child3
    const [state3, setState3] = useState([])
    const [friendlyName3, setFriendlyName3] = useState("Default Stat")
    const [description3, setDescription3] = useState("Default Stat")
    useEffect(() => {
        const splitBySubstring = (string, substring) => {
            try {
                if (string) {
                    let arr = string.split(substring)
                    return arr.map(x => {
                        if (x.substring(x.length - 3, x.length) != "}}}") {
                            x = x + "}}}"
                        }
                        return JSON.parse(x)
                    })
                }
                else return []
            }
            catch (err) {
                console.log(err)
            }
        }
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${config3["TypeName"]}/${config3["MethodName"]}`,
                data: config3
            })
            let response = result.data.Data
            setFriendlyName3(response.FriendlyName)
            setDescription3(response.Description)
            let splitResponse = splitBySubstring(response.TotalStat, "}}},")
            if (Array.isArray(splitResponse)) setState3([...splitResponse]);
        }
        fetchData()
    }, [])


    let resp = {
        child1: {
            state: state1,
            friendlyName: friendlyName1,
            description: description1,
            chartMargin
        },
        child2: {
            state: state2,
            friendlyName: friendlyName2,
            description: description2,
            chartMargin
        },
        child3: {
            state: state3,
            friendlyName: friendlyName3,
            description: description3,
            chartMargin
        }
    }

    const [currentTab, setCurrentTab] = useState(1)
    const handleClick = (id) => {
        setCurrentTab(id)
    }

    return (
        <div style={wrapperStyles}>
            <div style={cardHeaderStyle}>
                <div style={{ width: "90%", textOverflow: "ellipsis" }} className="card-header-title"><i style={headerFontStyle}>{props.title}</i></div>
                <div style={{ position: "absolute", right: "20px" }} className="card-header-description"><i style={{ float: "right" }} title={resp["child" + currentTab]["description"]}><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" opacity="0.8" viewBox="0 0 32 32" width="25px" height="25px"><path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z" /></svg></i></div>
            </div>
            <div style={cardStyles}>
                <AppBar position="static" color="default" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px 0px' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Yearly" />
                        <Tab label="Monthly" />
                        <Tab label="Count" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} onClick={() => handleClick(1)}>
                    <div style={tabStyles}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <Bar config={resp.child1} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} onClick={() => handleClick(2)}>
                    <div style={tabStyles}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <Bar config={resp.child2} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} onClick={() => handleClick(3)}>
                    <div style={tabStyles}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <Bar config={resp.child3} />
                        </div>
                    </div>
                </TabPanel>
            </div>
        </div>
    )
}


export default CardPieToggle
