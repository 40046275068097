import React from 'react';
import An from '../Helpers/AbbreviateNumber.js';
import useWindowSize from 'hooks/useWindowSize.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import UnassignedIcon from 'content/images/InfoBlock/Unassigned_Licenses.svg';
import UnusedIcon from 'content/images/InfoBlock/Unused_Licenses.svg';
import Notloggedin90days from 'content/images/InfoBlock/Not_Logged_In.svg';
import Neverloggedin from 'content/images/InfoBlock/Never_Logged_In.svg';
import DisabledAccount from 'content/images/InfoBlock/Disabled_Accounts.svg';
import TerminatedPerson from 'content/images/InfoBlock/Terminated_Person.svg';

const InfoBlock = (props) => {
  const windowSize = useWindowSize();
  let isMobile = windowSize.width < 756;
  const colors = [
    '#262fa1',
    '#9230c5',
    '#0078fa',
    '#ff6100',
    '#ba002c',
    '#ff007b',
    '#00a4d8',
    '#dc3131',
    '#00b83e',
    '#ffb800',
    '#265fb4',
    '#d50393',
    '#01c29f',
    '#ff6f57',
  ];
  let item = {
      flexGrow: 1,
    },
    setColor = colors[Math.floor(Math.random() * colors.length)],
    statHighlight = {
      fontSize: '1.8em',
    },
    statDescription = {
      fontSize: '1.4em',
    },
    statNumberStyle = {
      ...item,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '3px solid ' + setColor,
      borderRadius: '5px',
      backgroundColor: '#ffffff',
      padding: '10px',
      textAlign: 'center',
    },
    statFlex = {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      width: '100%',
      textAlign: 'center',
      margin: '0 12.5% 0 0',
    };
  let state = props.config.state,
    letterIcon = props.config.letterIcon,
    valueType = props.config.valueType;

  const valueTypeItems = {
    Integer: '',
    Currency: '$',
    Percentage: '%',
  };

  let formatTotalStat = (item) => {
    if (Object.keys(item).length > 2) {
      let res1 = An(item.Value1),
        res2 = An(item.Value2);
      switch (item.ValueType) {
        case 'Integer': {
          let res =
            valueTypeItems.Integer +
            ' ' +
            res1 +
            '/' +
            valueTypeItems.Integer +
            ' ' +
            res2;
          return res;
        }
        case 'Currency': {
          let res =
            valueTypeItems.Currency +
            res1 +
            '/' +
            valueTypeItems.Currency +
            res2;
          return res;
        }
        case 'Percentage': {
          let res =
            res1 +
            valueTypeItems[valueType] +
            '/' +
            res2 +
            valueTypeItems[valueType];
          return res;
        }
        case undefined: {
          return res1 + ' / ' + res2;
        }
      }
    } else return 0;
  };
  let formatIndividualStat = (value, valueType) => {
    let res = An(value);
    switch (valueType) {
      case 'Integer': {
        return res;
      }
      case 'Currency': {
        return valueTypeItems[valueType] + res;
      }
      case 'Percentage': {
        let res = res + valueTypeItems[valueType];
        return res;
      }
      case undefined: {
        return res;
      }
    }
  };
  let em = state.Values != undefined ? state.Values : [];
  let primary = {},
    highlight = {},
    secondary = [];
  em.forEach((item) => {
    if (item.Mode == 'Primary') {
      primary = item;
    } else if (item.Mode == 'Highlight') {
      highlight = item;
    } else secondary.push(item);
  });
  const listItems = secondary.map((item) => {
    return (
      <>
        <span
          style={{
            fontFamily: 'Rubik',
            fontWeight: 500,
            color: '#000000',
            ...statHighlight,
          }}
        >
          <strong>{formatTotalStat(item)}</strong>
        </span>
        <p style={{ marginTop: '-2px', ...statDescription }}> {item.Name}</p>
      </>
    );
  });
  function createData(item) {
    return {
      Name: item.Name,
      Value1: item.Value1,
      Value2: item.Value2,
      ValueType: item.ValueType,
      Mode: item.Mode,
    };
  }
  const primaryRow = [createData(primary)];
  const highlightRow = [createData(highlight)];

  const rowIcons = {
    Notloggedin90days,
    Neverloggedin,
    DisabledAccount,
    TerminatedPerson,
  };
  function createDataForSecondaryRows(item) {
    return {
      Name: item.Name,
      Value1: item.Value1,
      Value2: item.Value2,
      ValueType: item.ValueType,
      Mode: item.Mode,
      Icon: rowIcons['' + item.Name.replace(/\s+/g, '')],
    };
  }
  const secondaryRows = secondary.map((item) => {
    return createDataForSecondaryRows(item);
  });

  const tableHeaderStyle = {
    backgroundColor: '#eef0f4',
    width: '30%',
    fontSize: '13px',
    fontWeight: '900',
    textAlign: 'center',
  };
  const tableCellStyle = { width: '30%', borderBottom: 'none' };
  const tableItemNameStyle = { fontWeight: '900' };
  const valueBubbleStyle = {
    backgroundColor: 'red',
    backgroundColor: '#f9fafc',
    padding: '2px',
    textAlign: 'center',
    borderRadius: '20px',
  };
  const ValueBubble = (props) => {
    return (
      <div style={valueBubbleStyle}>
        <p
          style={{
            fontSize: props.fontSize + 'px',
            fontWeight: props.fontWeight,
          }}
        >
          {props.child}
        </p>
      </div>
    );
  };
  return (
    <div style={statFlex}>
      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: '#eef0f4' }}
                align="left"
              ></TableCell>
              <TableCell style={tableHeaderStyle} align="right">
                SPEND MONTHLY
              </TableCell>
              <TableCell style={tableHeaderStyle} align="right">
                SPEND YEARLY
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer style={{ marginBottom: '10px' }} component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {highlightRow.map((row) => (
              <TableRow key={row.Name}>
                <TableCell style={tableItemNameStyle}>
                  {
                    <span style={{ display: 'flex', direction: 'row' }}>
                      <img src={UnassignedIcon} alt="Logo" />
                      <p style={{ marginLeft: '3px' }}>{row.Name}</p>
                    </span>
                  }
                </TableCell>
                <TableCell style={{ width: '30%' }} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value1, row.ValueType)}
                    fontSize={20}
                    fontWeight={800}
                  />
                </TableCell>
                <TableCell style={{ width: '30%' }} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value2, row.ValueType)}
                    fontSize={20}
                    fontWeight={800}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer style={{ backgroundColor: '#fbfbfd' }} component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {primaryRow.map((row) => (
              <TableRow key={row.Name}>
                <TableCell style={tableItemNameStyle}>
                  {
                    <span style={{ display: 'flex', direction: 'row' }}>
                      <img src={UnusedIcon} alt="Logo" />
                      <p style={{ marginLeft: '3px' }}>{row.Name}</p>
                    </span>
                  }
                </TableCell>
                <TableCell style={tableCellStyle} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value1, row.ValueType)}
                    fontSize={20}
                    fontWeight={800}
                  />
                </TableCell>
                <TableCell style={tableCellStyle} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value2, row.ValueType)}
                    fontSize={20}
                    fontWeight={800}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer style={{ borderRadius: '5px' }} component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {secondaryRows.map((row) => (
              <TableRow key={row.Name} style={{ borderBottom: 'none' }}>
                <TableCell
                  style={{ ...tableItemNameStyle, borderBottom: 'none' }}
                >
                  {
                    <span style={{ display: 'flex', direction: 'row' }}>
                      <img src={row.Icon} alt="Logo" />
                      <p style={{ marginLeft: '3px' }}>{row.Name}</p>
                    </span>
                  }
                </TableCell>
                <TableCell style={tableCellStyle} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value1, row.ValueType)}
                    fontWeight={650}
                  />
                </TableCell>
                <TableCell style={tableCellStyle} align="right">
                  <ValueBubble
                    child={formatIndividualStat(row.Value2, row.ValueType)}
                    fontWeight={650}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InfoBlock;
