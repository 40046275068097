import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Gauge from '../Gauge.js';
import InfoBlock from '../InfoBlock.js';
import CardStat from '../Cards/CardStat.js';
import useWindowSize from 'hooks/useWindowSize.js';
import useAxiosOptions from 'hooks/useAxiosOptions.js';

const CardBlockGauge = (props) => {
    const getAxiosOptions = useAxiosOptions();
    const windowSize = useWindowSize();
    let isMobile = windowSize.width < 756;
    let wrapperStyles = {
        width: '100%',
        height: '100%',
        marginBottom: '2vw'
    };
    let styles = {
        padding: '5px',
        height: '100%',
        width: '100%',
        borderRadius: "0px 0px 5px 5px",
        backgroundColor: "#eef0f4"
    };
    let cardGaugeStyle = {
        width: '50%',
        float: 'right'
    },
        cardBlockStyle = {
            width: '50%',
            float: 'left'
        },
        cardGaugeStyleMob = {
            width: '100%'
        },
        cardBlockStyleMob = {
            width: '100%'
        }
    let cardHeaderStyle = {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#fbfbfd",
        padding: "25px"
    }
    let headerFontStyle = {
        fontFamily: "Rubik",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#000000",
        textAlign: "center"
    }
    let statRequestParamsBlock = {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 0, "start": 0, "pageLength": 500, "totalCount": -1 }
    }

    let configBlock = props.config.block
    statRequestParamsBlock.Parameters["empowerIDStatID"] = configBlock.statID
    let reqUrl = `/${statRequestParamsBlock["TypeName"]}/${statRequestParamsBlock["MethodName"]}`
    const [stateBlock, setStateBlock] = useState([])
    const [friendlyName, setFriendlyName] = useState("Default Stat")
    const [description, setDescription] = useState("")
    useEffect(() => {
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: reqUrl,
                data: statRequestParamsBlock
            })
            try {
                let response = result.data.Data
                setFriendlyName(response.FriendlyName)
                setDescription(response.Description)
                let totalStat = JSON.parse(response.TotalStat)
                setStateBlock(totalStat)
            }
            catch (ex) {
                throw ("Undesirable data \n" + ex.stack())
            }
        }
        fetchData()
    }, [])

    let resp_block = {
        state: stateBlock,
        friendlyName,
        description
    }

    let statRequestParamsGauge = {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 0, "start": 0, "pageLength": 500, "totalCount": -1 }
    }

    let configGauge = props.config.gauge
    statRequestParamsGauge.Parameters["empowerIDStatID"] = configGauge.statID
    const [stateGauge, setStateGauge] = useState([])
    const [friendlyNameGauge, setFriendlyNameGauge] = useState("Default Stat")
    const [descriptionGauge, setDescriptionGauge] = useState("")
    useEffect(() => {
        async function fetchData() {
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: reqUrl,
                data: statRequestParamsGauge,
            })
            try {
                let response = result.data.Data
                setFriendlyNameGauge(response.FriendlyName)
                setDescriptionGauge(response.Description)
                let totalStat = JSON.parse(response.TotalStat)
                setStateGauge(totalStat)
            }
            catch (ex) {
                throw ("Undesirable data \n" + ex.stack())
            }
        }
        fetchData()
    }, [])

    let resp_gauge = {
        state: stateGauge,
        description: descriptionGauge,
        friendlyName: friendlyNameGauge
    }

    const RegView = () => {
        return (
            <>
                <div style={cardBlockStyle}>
                    <div style={styles}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <InfoBlock config={resp_block} />
                        </div>
                    </div>
                </div>

                <div style={cardGaugeStyle}>
                    <div style={styles}>
                        <div style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                            <h2 style={headerFontStyle}>{resp_gauge.friendlyName}</h2>
                            <Gauge config={resp_gauge} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const MobView = () => {
        return (
            <>
                <div style={cardGaugeStyleMob}>
                    <div style={styles}>
                        <div style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                            <h2 style={headerFontStyle}>{resp_gauge.friendlyName}</h2>
                            <Gauge config={resp_gauge} />
                        </div>
                    </div>
                </div>
                <div style={cardBlockStyleMob}>
                    <div style={styles}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <InfoBlock config={resp_block} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div style={wrapperStyles}>
            {/* <div style={{ paddingBottom: '10px' }}><i style={headerFontStyle}>{resp_block.friendlyName}</i></div> */}
            {isMobile ? <MobView width={windowSize.width} /> : <RegView width={windowSize.width} />}
        </div>
    )
}

export default CardBlockGauge
