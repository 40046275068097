import React from 'react';
import { Layout } from 'components';
import { useQuery } from 'packages/core';
import TopOfMind from 'components/Sections/TopOfMind';
import PotentialSavings from 'components/Sections/PotentialSavings';
import Insights from 'components/Sections/Insights';
import LicenseUsage from 'components/Sections/LicenseUsage';

const ADA = () => {
  const query = useQuery();
  let id = query.get('AccountStoreID');

  let azureTenantExchangeUsageActiveVsInactiveLast30Days = {
    TypeName: 'EmpowerIDStatOrganizationView',
    MethodName: 'GetByEmpowerIDStatID',
    IncludedProperties: [
      'TotalStat',
      'FriendlyName',
      'EmpowerIDStatOrganizationID',
    ],
    Parameters: {
      empowerIDStatID: 572,
      start: 0,
      pageLength: 500,
      totalCount: -1,
    },
  };

  const navBlockStyle = {
    width: '100%',
    height: '103.6%',
    borderRadius: '5px',
    border: 'solid 1px #d2d2d9',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    textAlign: 'center',
  };
  const navTextStyle = {
    marginTop: '5%',
  };
  const navImageStyle = {
    marginTop: '20%',
  };
  return (
    <Layout>
      {/* <div class="navbar" style={{ float: 'left', width: '10%' }}>
                <div style={navBlockStyle}>
                    <img style={navImageStyle} src={licensePoolIcon}></img>
                    <p style={navTextStyle}>License Pools</p>
                </div>
                <div style={navBlockStyle}>
                    <img style={navImageStyle} src={licensePoolIcon}></img>
                    <p style={navTextStyle}>Subscriptions</p>
                </div>
                <div style={navBlockStyle}>
                    <img style={navImageStyle} src={licensePoolIcon}></img>
                    <p style={navTextStyle}>Tenants</p>
                </div>
                <div style={navBlockStyle}>
                    <img style={navImageStyle} src={licensePoolIcon}></img>
                    <p style={navTextStyle}>Licenses</p>
                </div>
            </div> */}
      <div className="az-analytics" style={{ marginLeft: '15px' }}>
        <table>
          <tr className="top-of-mind" style={{ maxWidth: '1900px' }}>
            <TopOfMind />
          </tr>
          <tr className="potential-savings">
            <PotentialSavings />
          </tr>

          <tr className="insights">
            <Insights />
          </tr>

          <tr class="license-usage">
            <LicenseUsage />
          </tr>
        </table>
      </div>
    </Layout>
  );
};

export default ADA;
