import React, { useState, useEffect } from 'react'
import axios from 'axios'
import GroupStat from '../GroupStat'
import useAxiosOptions from 'hooks/useAxiosOptions'

const colors = ["#262fa1", "#9230c5", "#0078fa", "#ff6100", "#ba002c", "#ff007b", "#00a4d8", "#dc3131", "#00b83e", "#ffb800", "#265fb4", "#d50393", "#01c29f", "#ff6f57"]
let setColor = colors[Math.floor(Math.random() * colors.length)]
const CardGroupedStat = (props) => {
    const getAxiosOptions = useAxiosOptions();
    let styles = {
        marginBottom: '10px',
        width: "100%"
    };
    let statRequestParams = {
        "TypeName": "EmpowerIDStatOrganizationView",
        "MethodName": "GetByEmpowerIDStatID",
        "IncludedProperties": ["TotalStat", "FriendlyName", "EmpowerIDStatOrganizationID"],
        "Parameters": { "empowerIDStatID": 0, "start": 0, "pageLength": 500, "totalCount": -1 }
    }

    let config = props.config
    statRequestParams.Parameters["empowerIDStatID"] = config.statID
    let icons = props.icons
    let itemsTotal = props.itemsTotal
    const [state, setState] = useState([])
    const [letterIcon, setLetterIcon] = useState("#")
    const [friendlyName, setFriendlyName] = useState("Default Stat")
    const [description, setDescription] = useState("")
    useEffect(() => {
        async function fetchData() {
            const splitBySubstring = (string, substring) => {
                try {
                    if (string) {
                        let arr = string.split(substring)
                        return arr.map(x => {
                            if (x.substring(x.length - 1, x.length) != "}") {
                                x = x + "}"
                            }
                            return JSON.parse(x)
                        })
                    }
                    else return []
                }
                catch (err) {
                    console.log(err)
                }
            }
            let result = await axios({
                ...getAxiosOptions(),
                method: 'POST',
                url: `/${statRequestParams["TypeName"]}/${statRequestParams["MethodName"]}`,
                data: statRequestParams,
            })
            let response = result.data.Data
            setFriendlyName(response.FriendlyName)
            setDescription(response.Description)
            let splitResponse = splitBySubstring(response.TotalStat, "},")
            if (Array.isArray(splitResponse)) setState([...splitResponse]);
        }
        fetchData()
    }, [])

    let resp = {
        state,
        friendlyName,
        description,
        icons
    }

    return (
        <div style={styles} className="card-container panel panel-default">
            <div style={{ height: '100%', width: '100%' }}>
                <GroupStat config={resp} />
            </div>
        </div>
    )
}

export default CardGroupedStat
