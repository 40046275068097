import React from 'react';
import useWindowSize from 'hooks/useWindowSize.js';

const Card = (props) => {
    const windowSize = useWindowSize()
    let isMobile = props.isMobile
    let wrapperStyles = {
        width: '100%',
        margin: '0.5% 0% 0.5% 0%'
    };
    let styles = {
        display: 'flex',
        flexWrap: 'wrap',
        height: '18em',
        width: '100%',
        padding: '40px',
        paddingTop: '25px',
        borderRadius: "0px 0px 5px 5px",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff"
    };
    let mobStyles = {
        display: 'flex',
        height: '18em',
        width: '100%',
        padding: '25px 40px 40px 20px',
        borderRadius: "0px 0px 5px 5px",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff"
    };
    let cardHeaderStyle = {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#fbfbfd",
        padding: "25px"
    }
    let headerFontStyle = {
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#000000"
    }

    const FullWidth = () => {
        return (
            <div style={{ ...styles, flexWrap: 'nowrap' }}>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[0]}</div>
                    {props.child1}
                </div>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[1]}</div>
                    {props.child2}
                </div>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[2]}</div>
                    {props.child3}
                </div>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[3]}</div>
                    {props.child4}
                </div>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[4]}</div>
                    {props.child5}
                </div>
                <div style={{ height: '100%', width: '30%', marginBottom: '20px', marginLeft: '10px' }}>
                    <div style={{ textAlign: 'center' }}>{props.groupTitles[5]}</div>
                    {props.child6}
                </div>
            </div>
        )
    }
    const HalfWidth = () => {
        return (
            <>
                <div style={mobStyles}>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[0]}</div>
                        {props.child1}
                    </div>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[1]}</div>
                        {props.child2}
                    </div>
                </div>
                <div style={mobStyles}>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[2]}</div>
                        {props.child3}
                    </div>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[3]}</div>
                        {props.child4}
                    </div>
                </div>
                <div style={mobStyles}>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[4]}</div>
                        {props.child5}
                    </div>
                    <div style={{ height: '100%', width: isMobile ? '100%' : '47.5%', marginBottom: '20px', marginLeft: '10px' }}>
                        <div style={{ textAlign: 'center' }}>{props.groupTitles[5]}</div>
                        {props.child6}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div style={wrapperStyles}>
            <div style={cardHeaderStyle}>
                <i style={headerFontStyle}>{props.title}</i>
            </div>
            {(windowSize.width < 1620) ? <HalfWidth /> : <FullWidth />}
        </div>
    )
}


export default Card